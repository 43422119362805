import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.regexp.replace";
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { Grid, Link, SEO } from 'components';
import { useNavIntro } from 'hooks';
import { renderText } from 'utils';
import styles from './styles/about.module.scss';
/**
 * Removes the brackets around text stored in code tags in
 * our Contentful rich text definitions.
 *
 * @param {string} text the text in the code tag
 * @returns the text content without surrounding brackets
 */

var getCodeContent = function getCodeContent(text) {
  return text.replace(/\[|\]/g, '');
};

var AboutPage = function AboutPage(_ref) {
  var _ref$data$page = _ref.data.page,
      body = _ref$data$page.body,
      intro = _ref$data$page.intro,
      seo = _ref$data$page.seo,
      pathname = _ref.location.pathname;
  useNavIntro();

  var _useState = useState(null),
      pageBody = _useState[0],
      setPageBody = _useState[1];

  var _useState2 = useState([]),
      navItems = _useState2[0],
      setNavItems = _useState2[1];
  /**
   * Renders the page header to include the location line.
   */


  var pageHeader = useMemo(function () {
    var _renderMark, _renderNode;

    var options = {
      renderMark: (_renderMark = {}, _renderMark[MARKS.CODE] = function (text) {
        return React.createElement("span", {
          className: styles.line
        }, new Array(20).fill(null).map(function (key, i) {
          return React.createElement("span", {
            key: i,
            className: styles.lineSegment
          }, "\xA0\xA0\xA0");
        }), React.createElement("span", {
          className: styles.visuallyHidden
        }, getCodeContent(text)));
      }, _renderMark),
      renderNode: (_renderNode = {}, _renderNode[BLOCKS.PARAGRAPH] = function (node, children) {
        return React.createElement("h1", null, children);
      }, _renderNode)
    };
    return renderText(intro, options);
  }, [intro]);
  /**
   * Creates the rendered page body and nav items using
   * the body render method. Pulls out the nav items from text
   * marked as code within the body.
   */

  var createBodyAndNavItems = function createBodyAndNavItems() {
    var _renderNode2;

    var navItems = [];
    var options = {
      renderNode: (_renderNode2 = {}, _renderNode2[BLOCKS.HEADING_3] = function (node, children) {
        var text = children.join(' ');
        var navItem = {
          slug: text.toLowerCase().replace(/\s/g, '-'),
          text: text
        };
        navItems.push(navItem);
        return React.createElement("h3", {
          id: navItem.slug
        }, text);
      }, _renderNode2)
    };
    var pageBody = renderText(body, options);
    setPageBody(pageBody);
    setNavItems(navItems);
  };

  useEffect(function () {
    createBodyAndNavItems();
  }, [body]);
  return React.createElement(Fragment, null, React.createElement(SEO, Object.assign({}, seo, {
    pathname: pathname
  })), React.createElement(Grid, {
    className: styles.pageContainer
  }, React.createElement("header", {
    className: styles.pageHeader
  }, pageHeader), React.createElement("section", {
    className: styles.pageBody
  }, navItems.length > 0 && React.createElement("div", {
    className: styles.pageNav
  }, React.createElement("ul", {
    className: styles.navList
  }, navItems.map(function (_ref2) {
    var slug = _ref2.slug,
        text = _ref2.text;
    return React.createElement("li", {
      key: slug,
      className: styles.navItem
    }, React.createElement(Link, {
      to: "#" + slug,
      className: styles.navItemLink
    }, text));
  }))), React.createElement("div", {
    className: styles.pageBodyContent
  }, pageBody))));
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      body: PropTypes.object.isRequired,
      intro: PropTypes.object.isRequired,
      seo: PropTypes.object.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};
export default AboutPage;
export var query = "3408602526";